import React, { useEffect, useState } from 'react';
import styles from './BrandInfo.module.scss';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select';
import { ChartsTooltip, ChartsXAxis, ChartsYAxis, LinePlot, ResponsiveChartContainer } from '@mui/x-charts';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';

export default function BrandInfo() {
    const [options,setOptions] = useState([]);
    const [dimensions, setDimensions] = useState({ width: 300, height: 300 })
    const [companyId, setCompanyId] = useState(JSON.parse(secureLocalStorage.getItem('user')).company_id);
    const [manual,setManual] = useState([]);
    const [info,setInfo] = useState({
        balance:1240.50,
        prefixes:100,
        agents:50,
        countries:50,
        graph: [1629,1214,1571,1500,1299,1311,1475,1920,2049,1982,2010,1560]
    })
    
    const updateDimensions = () => {
        const width = window.innerWidth * 0.2; // Example: 30% of the viewport width
        const height = window.innerHeight * 0.3; // Example: 30% of the viewport height
        setDimensions({ width, height });
    };
    const connect = () => {
        axios.post('https://www.pbxdiamondcall.com:8081/billing/account', {company_id:companyId})
            .then(res => {
                axios.post('https://www.pbxdiamondcall.com:8081/billing/history', {
                    id:res.data[0].id
                })
                .then((resl)=>{
                    console.log(resl);
                    setManual(resl.data);
                })
                .catch((err)=>console.log(err));
        }).catch(e => console.log(e))
        axios.get(`https://www.pbxdiamondcall.com:8081/billing/info/${companyId}`).then((res)=>{
            console.log(res.data);
            setInfo({
                balance: parseFloat(res.data.balance).toFixed(2),
                prefixes:res.data.prefixes,
                agents:res.data.agents,
                countries: res.data.countries,
                graph: res.data.graph
            })
        }).catch((err)=>console.log(err));
    }
    useEffect(() => {
        var arr = [];
        secureLocalStorage.getItem("brands")?.forEach((val)=>{
            arr.push({value:val.Id,label:val.Title});
        })
        setOptions(arr);
        window.addEventListener('resize', updateDimensions);
        updateDimensions(); // Initial setting
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    useEffect(()=>{
        connect();
    },[companyId])
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role !== 1 && role !== 7){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else
    return (
        <div className={styles.page}>
            <div className={styles.row1}>
                <div className={styles.cards}>
                    <div className={styles.card_balance}>
                        <div style={{position:"relative"}}>
                            <LocalAtmRoundedIcon className={styles.icon}/>
                        </div>
                        <div style={{height:"fit-content",display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center"}}>
                            <span className={styles.value}>
                                {info.balance}$
                            </span>
                            <span className={styles.label}>
                                Current balance
                            </span>
                        </div>
                    </div>
                    <div className={styles.card_prefix}>
                        <div style={{position:"relative"}}>
                            <PinOutlinedIcon className={styles.icon}/>
                        </div>
                        <span className={styles.value}>
                            {info.prefixes}
                        </span>
                        <span className={styles.label}>
                            Prefixes
                        </span>
                    </div>
                    <div className={styles.card_agents}>
                        <div style={{position:"relative"}}>
                            <AccountCircleOutlinedIcon className={styles.icon}/>
                        </div>
                        <span className={styles.value}>
                            {info.agents}
                        </span>
                        <span className={styles.label}>
                            Active agents
                        </span>
                    </div>
                    <div className={styles.card_something}>
                        <div style={{position:"relative"}}>
                            <LanguageOutlinedIcon className={styles.icon}/>
                        </div>
                        <span className={styles.value}>
                            {info.countries}
                        </span>
                        <span className={styles.label}>
                            Active countries
                        </span>
                    </div>
                </div>
                <div className={styles.graph}>
                    <span className={styles.title}>Expenses</span>
                    {
                        JSON.parse(secureLocalStorage.getItem('user')).login === "TestingGod" ?
                        <div style={{width:"10vw", position:"absolute", top:'1vh',right:"0.5vw",zIndex:10}}>
                            <Select 
                                options={options}
                                value={{
                                    label:options?.find((val)=>val.value==companyId)?.label,
                                    value:options?.find((val)=>val.value==companyId)?.value
                                }}
                                onChange={(e)=>setCompanyId(e.value)}
                                styles={{
                                    input: (baseStyles, state) => ({
                                        ...baseStyles,
                                        minHeight:"1.8vh !important;",
                                        height:"1.8vh",
                                        width:"100%",
                                        padding:"0px",
                                        color:"#fff",
                                    }),
                                    container: (baseStyles, state) => ({
                                        ...baseStyles,
                                        minHeight:"1.8vh !important;",
                                        height:"2.5vh",
                                        width:"100%",
                                        borderColor: state.isFocused ? '#3EA3FC' : "#3EA3FC;",
                                        borderRadius: "0.5vh",
                                        padding:"0px",
                                    }),
                                    valueContainer: (baseStyles,state) =>({
                                        ...baseStyles,
                                        minHeight:"1.8vh !important;",
                                        height:"2.5vh",
                                        fontSize:"1.4vh",
                                        marginBottom:"20%",
                                        paddingBottom:"0.4vh"
                                    }),
                                    indicatorsContainer: (baseStyles,state) =>({
                                        ...baseStyles,
                                        minHeight:"1.8vh !important;",
                                        height:"2.5vh",
                                        paddingTop:"0.2vh"
                                    }),
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        minHeight:"1.8vh !important;",
                                        height:"3vh",
                                        background:"#2A2A31",
                                        fontSize:"1.4vh",
                                        color:"#fff",
                                        borderRadius: "0.5vh",
                                        borderColor: state.isFocused ? '#3EA3FC' : "#3EA3FC",
                                        "&:hover": {
                                            borderColor: 'rgba(62, 163, 252, 0.212)'
                                        },
                                        boxShadow:"0px",
                                        padding:"0px",
                                        paddingLeft:"0.4vw",
                                    }),
                                    indicatorSeparator: (baseStyles, state) => ({
                                        dislay:"none"
                                    }),
                                    placeholder:(baseStyles, state) => ({
                                        ...baseStyles,
                                        color:"#fff",
                                    }),
                                    dropdownIndicator:(baseStyles, state) => ({
                                        ...baseStyles,
                                        color:"#fff",
                                        "svg":{
                                            height:"2vh",
                                            width:"auto"
                                        },
                                        paddingRight:"0.8vw"
                                    }),
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontSize:"1.4vh",
                                        color:"#000"
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        color:"#fff",
                                        alignSelf:"center",
                                    })
                                }}
                            />
                        </div>
                        :
                        null
                    }
                    <ResponsiveChartContainer
                    disableAxisListener={false}
                    xAxis={[
                        { 
                            data: ["Week 12","Week 11","Week 10","Week 9","Week 8","Week 7","Week 6","Week 5","Week 4","Week 3","Week 2","Week 1"],
                            scaleType: "band",
                            id: 'x-axis-id',
                            valueFormatter: (value) => value.toString() 
                        }
                    ]}
                    series={[
                        {
                            type: "line",
                            data: info.graph?info.graph:[0,0,0,0,0,0,0,0,0,0,0,0],
                            showMark: false,
                            label: "Expenses",
                            valueFormatter: (value) => `$${value}`
                        },
                    ]}
                    colors={[
                        '#ffab00'
                    ]}
                    sx={{
                        "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                            strokeWidth: 0,
                            fill: "#fff",
                            fontFamily: '"Ubuntu",sans-serif',
                        },
                        "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                            fontFamily: "'Ubuntu',sans-serif",
                        },
                        "& .MuiChartsTooltip-root": {
                            fontFamily: "'Ubuntu',sans-serif",
                            fill:"#000",
                            color:"#fff"
                        },
                        "& .MuiChartsAxis-tick ": {
                            stroke: "#fff",
                            strokeWidth:0
                        },
                        '& .MuiLineElement-root':{
                            strokeWidth:2
                        },
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                            strokeWidth: 1,
                            fill: "#fff",
                            fontFamily: "'Ubuntu',sans-serif",
                            transform:'scale(85%)', 
                        },
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                            stroke: "#fff",
                            strokeWidth: 1
                        },
                        "& .MuiLineHighlightElement-root": {
                            stroke: "#fff",
                            strokeWidth: 1
                        },
                        // leftAxis Line Styles
                        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                            stroke: "#fff",
                            strokeWidth: 1
                        },
                        "& .MuiChartsAxis-left": {
                            stroke: "#fff",
                            display:"none"
                        }
                    }}>
                        <ChartsTooltip />
                        <LinePlot />
                        <ChartsYAxis />
                        <ChartsXAxis position="bottom" axisId="x-axis-id" />
                    </ResponsiveChartContainer>
                </div>
                <div className={styles.table}>
                    <span className={styles.title}>Transactions</span>
                    <div className="card" style={{marginLeft:0, display:'flex' , alignItems:'center' , justifyContent:'center' ,             flexDirection:'column', background:"transparent", boxShadow:"none"}}>
                        <div className="card-body" style={{height:'32.5vh', background:"transparent"}}>
                            <ul className="list-group overflow-auto" style={{position: 'relative',maxHeight:'30vh', zIndex:1,marginTop:'2vh',borderRadius:0}}>
                                {
                                    manual.sort((a,b)=>dayjs(a.date).isAfter(b.date)?-1:1).map((val,ind)=>{
                                        return <li className="list-group-item list-group-item"
                                        style={{color:"#d3d2d2", background:"#232329",
                                        borderRadius:"0.6vh",marginBottom:"0.5vh",
                                        width:"18vw",
                                        borderTop:"0.1vh solid #8B8B8D",
                                        borderLeft:"0.1vh solid #8B8B8D",
                                        borderRight:"0.1vh solid #8B8B8D",
                                        borderBottom:"0.1vh solid #8B8B8D",
                                    }}
                                        key={ind}>
                                            <span style={{
                                                width:"8vw",display:"flex",alignItems:"center",justifyContent:"center",
                                                border:"0.1vh solid #d3d2d2", borderRadius:"0.2vh", marginBottom:"0.5vh"
                                            }}>
                                                {moment.parseZone(new Date(val.date)).local().format('YYYY-MM-DD HH:mm:ss')}
                                            </span>
                                            <div style={{
                                                    width:"16vw", display:"flex",alignItems:"center",justifyContent:"space-between"
                                                }}>
                                                <span>
                                                    {val.description}
                                                </span>
                                                <br />
                                                <span style={{color:val.cost>0?"#24D6A3":"#FF4861"}}>
                                                    {val.cost}$
                                                </span>
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        {
                            role===1 && 
                            <div className="card-footer" style={{display:'flex',alignItems:"center", flexDirection:'row'}} >
                                <button className='btn btn-label-success' data-bs-toggle="modal" data-bs-target="#modalTop"
                                style={{border:"0.1vh solid #24D6A3",backgroundColor:"transparent",margin:4, width:150,color:"#24D6A3"}}>
                                    Replenishment
                                </button>
                                <button className='btn btn-label-danger' data-bs-toggle="modal" data-bs-target="#modalDown"
                                style={{border:"0.1vh solid #FF4861",backgroundColor:"transparent",margin:4, width:150,color:"#FF4861"}}>
                                    Withdrawal
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
