import styles from './Reports.module.scss'
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import Box from '@mui/material/Box';
import PhoneCallbackRoundedIcon from '@mui/icons-material/PhoneCallbackRounded';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import secureLocalStorage from 'react-secure-storage';
import PhoneDisabledRoundedIcon from '@mui/icons-material/PhoneDisabledRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import Countries from '../../resources/countries.json';
import logo1 from '../../resources/logo.png'
import PropTypes from 'prop-types';
import { DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { Button, Pagination, PaginationItem, Stack, styled } from '@mui/material';
import Select from 'react-select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SettingsInputAntennaSharp } from '@mui/icons-material';
import dayjs from 'dayjs';
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>Empty set</Box>
      </StyledGridOverlay>
    );
  }
  function customCheckbox(theme) {
    return {
      '& .MuiCheckbox-root svg': {
        width: 16,
        height: 16,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
          }`,
        borderRadius: 2,
      },
      '& .MuiCheckbox-root svg path': {
        display: 'none',
      },
      '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
        backgroundColor: '#1890ff',
        borderColor: '#1890ff',
        transition: 'background 3s !important',
        transition: 'color 3s !important',
      },
      '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
        position: 'absolute',
        display: 'table',
        border: '2px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        transform: 'rotate(45deg) translate(-50%,-50%)',
        opacity: 1,
        transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
        content: '""',
        top: '50%',
        left: '39%',
        width: 5.71428571,
        height: 9.14285714,
      },
      '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
        width: 8,
        height: 8,
        backgroundColor: '#1890ff',
        transform: 'none',
        transition: 'background 3s !important',
        transition: 'color 3s !important',
        top: '39%',
        border: 0,
      },
    };
  }
  const styleDark = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
    backgroundColor: '#252424',
    width: "1200px",
    height: "600px"
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
    backgroundColor: '#fff',
    width: "1200px",
    height: "600px"
  };
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#121212',
      transition: 'background 3s !important',
      transition: 'color 3s !important',
    },
    '& .MuiDataGrid-rowsContainer': {
      minHeight:500
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
  
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    ...customCheckbox(theme),
  }));
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }
  const PAGE_SIZE = 15;
  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '280px', mr: 1 }}>
          <LinearProgress color="success" variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

  
  function LinearProgressWithLabelPref(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '250px', mr: 1 }}>
          <LinearProgress color="success" variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  LinearProgressWithLabelPref.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
export default function ReportsRebrending(){
  const [startDate, setStartDate] = useState(dayjs(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(dayjs(new Date()).endOf('day'));
    const isDarkMode = true;
    const { id } = useParams();
    const [companies,SetCompanies] = useState([]);
    const [countryData,setCountryData] = useState([]);
    const [prefixData,setPrefixData] = useState([]);
    const [stats,setStats] = useState({
        online:0,
        acd:0,
        answer:0,
        noanswer:0,
        count:0,
        title:"BrandName"
    });
    const [companyChoose,SetCompanyChoose] = useState(false);
    
    const StyledDatePickerInput = styled(DatePicker)({
      borderRadius: '10px',
      '& .MuiFormLabel-root': {
          color:  '#688CF2',
          fontFamily: "'Ubuntu', sans-serif",
      },
      '& .MuiInputBase-input': {
          outline: 'none',
          border: 'none',
          color: '#688CF2',
          fontFamily: "'Ubuntu', sans-serif",
      },

      '& .MuiOutlinedInput-notchedOutline': {
          outline: 'none',
          borderColor: '#688CF2',
      },
      "& .MuiOutlinedInput-input": { outline: 'none', },
      '& .MuiSvgIcon-root': {
          fill: '#688CF2',
      }
  });
    const columnsPrefix = [
      {
          field: 'prefix',
          headerName: 'Prefix',
          width: 250,
          headerAlign: 'start',
          editable: false,
          renderCell: (params) => {
            return (
              <div style={{display:'flex',alignItems:"center",justifyContent:"start",gap:5}}>
                  <span>{params.row.prefix}</span>
              </div>
            );
          },
        },
        {
          field: 'TotalCount',
          headerName: 'Calls',
          width: 120,
          headerAlign: 'start',
          editable: false,
          renderCell: (params) => {
            return (
                  <span>{params.row.TotalCount}({parseFloat(+params.row.TotalCount/prefixData.reduce((a,b)=>{return (a+b.TotalCount)},0)*100).toFixed(2)}%)</span>
            );
          },
        },
        {
          field: 'avg',
          headerName: 'Success',
          width: 450,
          headerAlign: 'start',
          editable: false,
          renderCell: (params) => {
            return (
              <LinearProgressWithLabelPref value={params.row.avg*100}  />
            );
          },
        },
    ];
    const columns = [
        {
            field: 'Country',
            headerName: 'Country',
            width: 150,
            headerAlign: 'start',
            editable: false,
            renderCell: (params) => {
              return (
                <div style={{display:'flex',alignItems:"center",justifyContent:"start",gap:5}}>
                    <img src={Countries.find((obj)=>obj.name.official==params.row.Country ||obj.name.common==params.row.Country || obj.altSpellings.includes(params.row.Country))?.flags?.png} alt={Countries.find((obj)=>obj.name.common==params.row.Country)?.flags?.alt} height={24} className="me-2" width={24} style={{borderRadius:'50%'}} />
                    <span>{params.row.Country}</span>
                </div>
              );
            },
          },
          {
            field: 'TotalCount',
            headerName: 'Calls',
            width: 120,
            headerAlign: 'start',
            editable: false,
            renderCell: (params) => {
              return (
                    <span>{params.row.TotalCount}({parseFloat(+params.row.TotalCount/countryData.reduce((a,b)=>{return (a+b.TotalCount)},0)*100).toFixed(2)}%)</span>
              );
            },
          },
          {
            field: 'avg',
            headerName: 'Success',
            width: 500,
            headerAlign: 'start',
            editable: false,
            renderCell: (params) => {
              return (
                <LinearProgressWithLabel value={params.row.avg*100} />
              );
            },
          },
    ];
    const [paginationModel, setPaginationModel] = useState({
      pageSize: PAGE_SIZE,
      page: 0,
    });
    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });
    const lightTheme = createTheme({
    palette: {
        
        mode: 'light',
    },
    }); 
    const [isLoading , setIsLoading] = useState(true)
    const handleChangeStartDate = (newValue) => {
        // Устанавливаем время на полночь
        const midnightStartDate = dayjs(newValue).startOf('day');
        setStartDate(midnightStartDate);
    };

    const handleChangeEndDate = (newValue) => {
        // Устанавливаем время на полночь
        const midnightEndDate = dayjs(newValue).endOf('day');
        setEndDate(midnightEndDate);
    };
    useEffect(()=>{
        axios.get('https://www.pbxdiamondcall.com:8081/companies')
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.Id,
                        label:el.Title
                    })
            if(el.Id == JSON.parse(secureLocalStorage.getItem('user')).company_id)
            SetCompanyChoose({
              value:el.Id,
              label:el.Title
          })
        })
        SetCompanies(arr);
        setStats({
            online:0,
            acd:0,
            answer:0,
            noanswer:0,
            count:0,
            title:companyChoose.label
        })
       })
       .catch((err)=>console.log(err))
    },[])
    useEffect(()=>{
      GetReport();
    },[companyChoose])
    const GetReport = async() => {
        setIsLoading(true);
        axios.post('https://www.pbxdiamondcall.com:8081/dailyreport',{company_id:companyChoose.value,start:startDate,end:endDate})
        .then((res)=>{
            if(res.data.countries.length>0 && res.data.calls.length>0){
            setCountryData(res.data.countries);
            setStats(res.data.calls[0]);
            setPrefixData(res.data.prefixes);
            setIsLoading(false);
            }
            else{
                setCountryData([])
                setPrefixData([])
                setStats({
                    online:0,
                    acd:0,
                    answer:0,
                    noanswer:0,
                    count:0,
                    title:"BrandName"
                })
                setIsLoading(false);
            }
        }).catch((err)=>console.log(err));
    }
    let role = JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem('user')).role_id:6;
    if(role != 1 && role!=7 ){
        return <Navigate to="/onlinecalls" replace={true}/>
    }
    else
    return(
    <ThemeProvider theme={isDarkMode?darkTheme:lightTheme}>
        <div className={isDarkMode?styles.bodyDark:styles.body}>
            <defs>
                <svg style={{ position: 'absolute', top: '0', right: '0' }}  >
                    <linearGradient id="SvgjsLinearGradient4847" x1="0" y1="0" x2="0" y2="1">
                        <stop id="SvgjsStop4848" stop-opacity="0.6" stop-color="rgba(119,237,139,0.6)" offset="0"></stop>
                        <stop id="SvgjsStop4849" stop-opacity="0.5" stop-color="rgba(119,237,139,0.4)" offset="0.5"></stop>
                        <stop id="SvgjsStop4850" stop-opacity="0" stop-color="rgba(0,120,103,0)" offset="1"></stop>
                    </linearGradient>
                </svg>
                <svg style={{ position: 'absolute', top: '0', right: '0' }}  >
                    <linearGradient id="SvgjsLinearGradient4849" x1="0" y1="0" x2="0" y2="1">
                        <stop id="SvgjsStop4848" stop-opacity="0.6" stop-color="rgba(119,237,139,0.6)" offset="0"></stop>
                        <stop id="SvgjsStop4849" stop-opacity="0.5" stop-color="rgba(119,237,139,0.4)" offset="0.5"></stop>
                        <stop id="SvgjsStop4850" stop-opacity="0" stop-color="rgba(0,120,103,0)" offset="1"></stop>
                    </linearGradient>
                </svg>
                <svg style={{ position: 'absolute', top: '0', right: '0' }}  >
                    <linearGradient id="SvgjsLinearGradient4848" x1="0" y1="0" x2="0" y2="1">
                        <stop id="SvgjsStop4848" stop-opacity="0" stop-color="rgba(255,48,48,0)" offset="0"></stop>
                        <stop id="SvgjsStop4849" stop-opacity="0.5" stop-color="rgba(255,48,48,0.4)" offset="0.5"></stop>
                        <stop id="SvgjsStop4850" stop-opacity="0.6" stop-color="rgba(255,48,48,0.6)" offset="1"></stop>
                    </linearGradient>
                </svg>
            </defs>
            <div className={styles.page}>
              {
                JSON.parse(secureLocalStorage.getItem('user')).role_id==1?
                <div style={{display:'flex',flexDirection:'row',gap:10,alignItems:'center',marginBottom:"20px",justifyContent:'center'}}>
                  
                    <Select
                        onChange={(e)=>SetCompanyChoose({label:e.label,value:e.value})}

                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                background: "none",
                                color: '#5FBDF9',
                                borderColor: '#5FBDF9',
                                borderRadius: "5px",
                                width: '257px',
                                textAlign: 'left',
                                height: '56px',
                                outline: 'none',
                                border: isDarkMode ?
                                    state.isFocused ? "1px solid #5FBDF9" : "1px solid #5FBDF9" :
                                    state.isFocused ? "1px solid #5FBDF9" : "1px solid #5FBDF9",
                                // This line disable the blue border
                                boxShadow: state.isFocused ? '#5FBDF9' : '#5FBDF9',
                                '&:hover': {
                                    border: isDarkMode ?
                                        state.isFocused ? "1px solid #5FBDF9" : "1px solid #5FBDF9" :
                                        state.isFocused ? "1px solid #5FBDF9" : "1px solid #5FBDF9",
                                }
                            }),
                            dropdownIndicator: (baseStyles, state) => ({
                                ...baseStyles,
                                color: '#5FBDF9',

                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isDarkMode? isSelected ? undefined : isFocused ? "rgba(255, 193, 172 ,0.8)" : undefined:isSelected ? undefined : isFocused ? "rgba(255, 48, 48,0.5)" : undefined,
                                    color: isSelected ? '#fff' : '#fff',
                                    textAlign: 'left',
                                    '&:hover': {
                                        backgroundColor: isDarkMode? isSelected ? "rgba(255, 193, 172 ,0.8)" : "none": isSelected ? "rgba(255, 48, 48,0.5)": "none"
                                    }
                                };
                            },
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: '#5FBDF9',
                            }),
                            placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                background: "none",
                                color: '#5FBDF9'
                            }),
                            menuList: (baseStyles, state) => ({
                                ...baseStyles,
                                color: '#5FBDF9',
                                borderRadius: "5px",
                                backgroundColor: 'rgba(37,36,36 , 0.5)'

                            }),
                        }}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={'None'}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        name="color"
                        placeholder='Select brand'
                        options={companies}
                    />
                    <div style={{width:'100%',display:'flex',flexDirection:'row', gap:30}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StyledDatePickerInput
                                            slotProps={{
                                                layout: {
                                                    sx: {
                                                        ' .MuiPickersCalendarHeader-label': {
                                                            fontFamily: '"Ubuntu" , sans-serif',
                                                        },
                                                        '.MuiPickersDay-root': {
                                                            fontFamily: '"Ubuntu" , sans-serif',
                                                        },
                                                    }
                                                }
                                            }}
                                            label="От"
                                            value={startDate}
                                            onChange={handleChangeStartDate}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StyledDatePickerInput
                                            slotProps={{
                                                layout: {
                                                    sx: {
                                                        ' .MuiPickersCalendarHeader-label': {
                                                            fontFamily: '"Ubuntu" , sans-serif',
                                                        },
                                                        '.MuiPickersDay-root': {
                                                            fontFamily: '"Ubuntu" , sans-serif',
                                                        },
                                                    }
                                                }
                                            }}
                                            label="До"
                                            value={endDate}
                                            onChange={handleChangeEndDate}
                                        />
                                    </LocalizationProvider>
                            </div>
                    {/* <Button 
                    sx={{height:'55px'}}
                    variant='outlined'
                    disabled={!companyChoose} 
                    onClick={()=>GetReport()}
                    >Get report</Button> */}
                </div>
                :''
              }
            <defs>
                <linearGradient id="myGradient" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="gold" />
                <stop offset="95%" stopColor="red" />
                </linearGradient>
            </defs>
                
                {isLoading
                ?
                <div className={styles.loader}>
                    <LinearProgress sx={{ width: '50%' }} color='inherit' />
                </div>
                :
                <div style={{gap:20,display:'flex',flexDirection:'row', width:"1600px", flexWrap:'wrap'}}>
                
                
                <div className={styles.countrystats}>
                    <div className={styles.reportheader}>
                        <h1><img src={logo1} alt='logo' width={40}/>[{stats.title}] Daily Country Report</h1>
                    </div>
                    <StyledDataGrid
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                        disableCellSelectionOnClick
                        autoHeight={true}
                        sx={{
                            border: isDarkMode ? 'none' : '1px solid lightgray',
                            transition: 'background color 3s !important',
                            width: "600px",
                            backgroundColor: isDarkMode ? '#2A2A31' : "#fff",
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            color:"#d3d3d2",
                            padding: "10px",
                            minHeight:900,
                            fontFamily: "'Ubuntu' , sans-serif",
                            '--DataGrid-overlayHeight': '800px',
                            '& .MuiDataGrid-footerContainer':{
                                display:"none"
                            }
                        }}
                        slots={{
                            pagination: CustomPagination,
                            noRowsOverlay: CustomNoRowsOverlay
                        }}
                        rows={countryData}
                        columns={columns}
                    />
                </div>
                
                <div className={styles.countrystats}>
                    <div className={styles.reportheader}>
                        <h1><img src={logo1} alt='logo' width={40}/>[{stats.title}] Daily Prefix Report</h1>
                    </div>
                    <StyledDataGrid
                        pagination={false}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                        disableCellSelectionOnClick
                        autoHeight={true}
                        sx={{
                            border: isDarkMode ? 'none' : '1px solid lightgray',
                            transition: 'background color 3s !important',
                            width: "600px",
                            backgroundColor: isDarkMode ? '#2A2A31' : "#fff",
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            color:"#d3d3d2",
                            padding: "10px",
                            minHeight:900,
                            fontFamily: "'Ubuntu' , sans-serif",
                            '--DataGrid-overlayHeight': '800px',
                            '& .MuiDataGrid-footerContainer':{
                                display:"none"
                            }
                        }}
                        slots={{
                            pagination: CustomPagination,
                            noRowsOverlay: CustomNoRowsOverlay
                        }}
                        rows={prefixData}
                        columns={columnsPrefix}
                    />
                </div><div className={styles.stats}>
                <div className={styles.reportheader}>
                    <h1><img src={logo1} alt='logo' width={40}/>[{stats.title}] Daily Report</h1>
                </div>
                    <div className={styles.secondrow}>
                        <div className={styles.infocontainer}>
                            <div className={styles.time}>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <svg style={{ position: 'absolute' }}>
                                    <linearGradient id="SvgjsLinearGradient1948" x1="0" y1="0" x2="0" y2="1"><stop id="SvgjsStop1947" stop-opacity="1" stop-color="#a466af" offset="0"></stop><stop id="SvgjsStop1948" stop-opacity="1" stop-color="#CE93D8" offset="1"></stop></linearGradient>
                                    </svg>
                                    <CircularProgress size='6rem' variant="determinate" thickness={2.6} value={100} sx={{
                                        borderRadius: '50%',
                                        boxShadow: `inset 0 0 0 ${2.6 / 44 * 6}rem ${'rgba(145, 158, 171, 0.16)'}`,
                                        [`& .${circularProgressClasses.circle}`]: {
                                            stroke: 'url(#SvgjsLinearGradient1948)',
                                            strokeLinecap: 'round',
                                        },

                                    }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontFamily: '"Ubuntu" , sans-serif', color: "#fff", fontSize: '16px' }}>
                                            {/* {`${Math.round(data.answer/(data.answer+data.noanswer)*100)}%`} */}
                                        </Typography>
                                    </Box>
                                </Box>
                                <div style={{width:'60%',textAlign:'left'}}>
                                <h2>{stats.online} sec.</h2>
                                <p>Time On Line</p>
                                </div>
                                <AccessTimeRoundedIcon sx={{width:'100px' , height:'100px',position:'absolute' , right:-10,transform:'rotateY(180deg)' , color:'rgba(40,46,53,0.16)'}}/>
                            </div>
                            <div className={styles.calls}>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <svg style={{ position: 'absolute' }}>
                                    <linearGradient id="SvgjsLinearGradient1946" x1="0" y1="0" x2="0" y2="1"><stop id="SvgjsStop1947" stop-opacity="1" stop-color="#61f3f3" offset="0"></stop><stop id="SvgjsStop1948" stop-opacity="1" stop-color="#00b8d9" offset="1"></stop></linearGradient>
                                    </svg>
                                    <CircularProgress size='6rem' variant="determinate" thickness={2.6} value={100} sx={{
                                        borderRadius: '50%',
                                        boxShadow: `inset 0 0 0 ${2.6 / 44 * 6}rem ${'rgba(145, 158, 171, 0.16)'}`,
                                        [`& .${circularProgressClasses.circle}`]: {
                                            stroke: 'url(#SvgjsLinearGradient1946)',
                                            strokeLinecap: 'round',
                                        },

                                    }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontFamily: '"Ubuntu" , sans-serif', color: "#fff", fontSize: '16px' }}>
                                        </Typography>
                                    </Box>
                                </Box>
                                <div style={{width:'60%',textAlign:'left'}}>
                                <h2>{stats.acd} sec.</h2>
                                <p>Average Call Duration</p>
                                </div>
                                <MoreTimeRoundedIcon sx={{width:'100px' , height:'100px',position:'absolute' , right:-10,transform:'rotateY(180deg)' , color:'rgba(40,46,53,0.16)'}}/>
                            </div>
                            <div className={styles.acr}>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <svg style={{ position: 'absolute' }}>
                                    <linearGradient id="SvgjsLinearGradient1939" x1="0" y1="0" x2="0" y2="1"><stop id="SvgjsStop1947" stop-opacity="1" stop-color="#3ead54" offset="0"></stop><stop id="SvgjsStop1948" stop-opacity="1" stop-color="#4bdb68" offset="1"></stop></linearGradient>
                                    </svg>
                                    <CircularProgress size='6rem' variant="determinate" thickness={2.6} value={+stats.count!=0?+(stats.answer/+stats.count)*100:0} sx={{
                                        borderRadius: '50%',
                                        boxShadow: `inset 0 0 0 ${2.6 / 44 * 6}rem ${'rgba(145, 158, 171, 0.16)'}`,
                                        [`& .${circularProgressClasses.circle}`]: {
                                            stroke: 'url(#SvgjsLinearGradient1939)',
                                            strokeLinecap: 'round',
                                        },

                                    }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontFamily: '"Ubuntu" , sans-serif', color: "#fff", fontSize: '16px' }}>
                                            {`${Math.round(+stats.count!=0?+(stats.answer/+stats.count)*100:0)}%`}
                                        </Typography>
                                    </Box>
                                </Box>
                                <div style={{width:'60%',textAlign:'left'}}>
                                <h2>{+stats.answer}</h2>
                                <p>Success Calls</p>
                                </div>
                                <PhoneCallbackRoundedIcon sx={{width:'100px' , height:'100px',position:'absolute' , right:-10,transform:'rotateY(180deg)' , color:'rgba(40,46,53,0.16)'}}/>
                            </div>
                            <div className={styles.acd}>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <svg style={{ position: 'absolute' }}>
                                        <linearGradient id="SvgjsLinearGradient12672" x1="0" y1="0" x2="0" y2="1"><stop id="SvgjsStop12673" stop-opacity="1" stop-color="#ffc1ac" offset="0"></stop><stop id="SvgjsStop12674" stop-opacity="1" stop-color="#ff3030" offset="1"></stop></linearGradient>
                                    </svg>
                                    <CircularProgress size='6rem' variant="determinate" thickness={2.6} value={Math.round(+stats.count!=0?+(stats.noanswer/+stats.count)*100:0)} sx={{
                                        borderRadius: '50%',
                                        boxShadow: `inset 0 0 0 ${2.6 / 44 * 6}rem ${'rgba(145, 158, 171, 0.16)'}`,
                                        [`& .${circularProgressClasses.circle}`]: {
                                            stroke: 'url(#SvgjsLinearGradient12672)',
                                            strokeLinecap: 'round',
                                        },

                                    }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontFamily: '"Ubuntu" , sans-serif', color: "#fff", fontSize: '16px' }}>
                                            {`${Math.round(+stats.count!=0?+(stats.noanswer/+stats.count)*100:0)} %`}
                                        </Typography>
                                    </Box>
                                </Box>
                                <div style={{width:'60%',textAlign:'left'}}>
                                <h2>{stats.noanswer}</h2>
                                <p>Failed Calls</p>
                                </div>
                                <PhoneDisabledRoundedIcon sx={{width:'100px' , height:'100px',position:'absolute' , right:-10 , color:'rgba(40,46,53,0.16)'}}/>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                }
            </div>
        </div>
        
    </ThemeProvider>
    )
    
}